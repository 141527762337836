import React from 'react'
import './landingPage.css'

function LangingPage() {
    return (
        <div className="container  login-container">
        <div class="card">
        <div class="card-content">

        <h3><i class="fas fa-book-reader"> </i> StoryBooks</h3>
<div class="section">
<p class="lead"><b>create a public and private stories from your life</b></p>
</div>
<div class="divider"></div>
<div class="section">

{/* <button type="submit" >Register</button> */}
<p class="lead"><b>create a public and private stories from your life</b></p>
</div>

        </div>
      </div> 
</div>
    )
}

export default LangingPage
