import React from "react";
import LangingPage from "./Components/LangingPage";
import Registerpage from "./Components/Registerpage";
import Dashboard from "./excell/Dashboard";
import Login from "./login/Login";
import { useStateValue } from "./redux/StateProvider";
import "react-toastify/dist/ReactToastify.css";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
} from "react-router-dom";

function App() {
  const [{ user }, dispatch] = useStateValue();
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/">
            <Registerpage />
          </Route>
          <Route path="/dashboard">
            {user === "" ? <Login /> : <Dashboard />}

            {/* <Login/>  */}
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
