import React, { useState, useEffect } from "react";
import { Customers } from "./Customers";
// import { Header } from './Header'
import logo from "../logo/logo.png";
import "./Dashboard.css";
import axios from "axios";
// import CircularProgress from "@material-ui/core/CircularProgress";
import { CircularProgress, StepButton } from "@material-ui/core";
import { local } from "../onlin/Online";
// import Nodata from '../Components/Nodata'
import { Empty, List } from "antd";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import { ExportCSV } from "./ExportCSV";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

function Dashboard() {
  const [custom, setCustom] = useState([]);
  const [isloading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");

  useEffect(() => {
    const insert = async () => {
      await axios.get(`${local}customer`, {}).then((user) => {
        setCustom([user.data]);
        setIsLoading(false);
      });
    };
    insert();
    console.log(custom);
  }, []);

  //delete
  const Deletee = () => {
    const delet = async () => {
      await axios
        .post(`${local}delete`, {
          id,
        })
        .then((user) => {
          console.log(user);
        });
    };
    delet();
    handleClose();
  };

  const handleClickOpen = (e) => {
    setId(e);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (isloading) {
    return (
      <>
        <div className="circle">
          <div className="haeda">
            <h5 className="lb">
              <b>First Aid Training For Domestic Managers</b>{" "}
            </h5>
          </div>
          <CircularProgress />;
        </div>
      </>
    );
  }
  const customers = () => {
    let custs = [];
    custom[0].map((su) =>
      custs.push({
        Employername: `${
          su.emFirstname + " " + su.emSecondname + " " + su.emLaststname
        }`,
        Employeremail: `${su.emEmail}`,
        Employerphone: `${su.emPhone}`,
        Employerlocation: `${su.location}`,
        Managername: `${
          su.maFirstname + " " + su.maSecondname + " " + su.maLastname
        }`,
        Managerphone: `${su.maPhone}`,
        mpesacode: `${su.mpesaCode}`,
        Edit:
          su.mpesaCode === undefined ? (
            ""
          ) : (
            <DeleteForeverOutlinedIcon
              className="dt"
              onClick={() => {
                handleClickOpen(su._id);
              }}
            />
          ),
      })
    );

    return custs;
  };

  const state = {
    customers: customers(),
    fileName: "Customers",
  };

  console.log(custom);

  return (
    <div className="container  login-containerr">
      <div class="card r">
        <div class="card-content ">
          <img src={logo} alt="" className="logoo" />
          <h5 className="lb">
            <b>First Aid Training For Domestic Managers</b>{" "}
          </h5>
          <h5 className="lb l">
            on 28<sup>th</sup> October 2023{" "}
          </h5>

          <div class="divider poo"></div>

          <div>
            <div className="row  ">
              <div className="col-md-8 ho">
                <h2>Customers</h2>
              </div>
              <div className="col-md-4 center y">
                <ExportCSV
                  csvData={state.customers}
                  fileName={state.fileName}
                />
              </div>
            </div>
            <Customers customers={customers()} />
          </div>
        </div>
      </div>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Warning!!!"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are sure you want to delete this customer?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Disagree
            </Button>
            <Button onClick={Deletee} color="primary">
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

export default Dashboard;
