import React from "react";
import Table from "react-bootstrap/Table";

export const Customers = ({ customers }) => {
  const CustomerRow = (customer, index) => {
    return (
      <tr key={index} className="even">
        <td> {index + 1} </td>
        <td>{customer.Employername}</td>
        <td>{customer.Employeremail}</td>
        <td>{customer.Employerphone}</td>
        <td>{customer.Employerlocation}</td>
        <td>{customer.Managername}</td>
        <td>{customer.Managerphone}</td>
        <td>{customer.mpesacode}</td>
        <td>{customer.Edit}</td>
      </tr>
    );
  };

  const CustomerTable = customers.map((cust, index) =>
    CustomerRow(cust, index)
  );

  const tableHeader = (
    <thead className="bgvi">
      <tr>
        <th></th>
        <th>Employer Name</th>
        <th>Employer Email</th>
        <th>Employer Phone Number</th>
        <th>Employer Location</th>
        <th>Manager Name</th>
        <th>Manager Phone</th>
        <th>Mpesa Code</th>
      </tr>
    </thead>
  );

  return (
    <Table striped bordered hover>
      {tableHeader}
      <tbody>{CustomerTable}</tbody>
    </Table>
  );
};
