import React, { useState } from "react";
import "./Registerpage.css";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import logo from "../logo/logo.png";
import { online, local } from "../onlin/Online";
import Popup from "./Popup";
import LangingPage from "./LangingPage";
import { phonenumber } from "../Allfun/Allfunn";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

import { Link, useHistory } from "react-router-dom";

function Registerpage() {
  const [eFirstname, setEfirstname] = useState("");
  const [eSecondname, setEsecondname] = useState("");
  const [elastName, setElastName] = useState("");
  const [ephone, setEphone] = useState("");
  const [eEmail, setEemail] = useState("");
  const [elocation, setElocation] = useState("");
  //domestic
  const [mFirstname, setMfirstname] = useState("");
  const [mSecondname, setMsecondname] = useState("");
  const [mlastName, setMlastName] = useState("");
  const [mphone, setMphone] = useState("");
  const [mpesaCode, setMpesa] = useState("");
  const [openPop, setOpenPop] = useState(false);
  const [erro, setErro] = useState(false);

  const registerSuccess = () => toast.success("Registered successfully");
  const registererror = () => toast.error("Error during registration");
  const registerAllField = () => toast.error("All fields are required");

  const clearData = () => {
    document.getElementById("a").value = "";
    document.getElementById("b").value = "";
    document.getElementById("c").value = "";
    document.getElementById("d").value = "";
    document.getElementById("e").value = "";
    document.getElementById("f").value = "";
    document.getElementById("g").value = "";
    document.getElementById("h").value = "";
    document.getElementById("i").value = "";
    document.getElementById("j").value = "";
    document.getElementById("k").value = "";
  };

  const mee = (e) => {
    e.preventDefault();
    console.log("click");
  };

  const remm = () => {
    const elmnt = document.getElementById("div11");
    elmnt.remove();
  };

  // const err = () => {
  //   const para = document.createElement("h6");
  //   para.setAttribute("id", "div11");
  //   const node = document.createTextNode(
  //     "enter all the fields marked with a star *"
  //   );
  //   para.appendChild(node);

  //   const element = document.getElementById("div1");
  //   element.appendChild(para);

  //   setTimeout(remm, 3000);
  // };

  const submitt = async (e) => {
    e.preventDefault();

    if (
      eFirstname === "" ||
      elastName === "" ||
      ephone === "" ||
      elocation === "" ||
      mFirstname === "" ||
      mlastName === "" ||
      mpesaCode === "" ||
      mphone === ""
    ) {
      registerAllField();
    } else {
      document.getElementById("subm").style.visibility = "hidden";
      await axios
        .post(`${local}register`, {
          eFirstname,
          eSecondname,
          elastName,
          ephone,
          eEmail,
          elocation,
          elocation,
          mFirstname,
          mSecondname,
          mlastName,
          mphone,
          mpesaCode,
        })
        .then((user) => {
          console.log(user);
          registerSuccess();
          clearData();
          document.getElementById("subm").style.visibility = "visible";
        })
        .catch((error) => {
          registererror();
        });
    }
  };
  return (
    <>
      <div className="container  login-container">
        <div class="card r">
          <div class="card-content">
            <img src={logo} alt="" className="logo" />

            <h5 className="lb">
              <b>First Aid Training For Domestic Managers</b>{" "}
            </h5>
            <h5 className="lb l">
              on 28<sup>th</sup> October 2023{" "}
            </h5>
            <div class="section"></div>
            <div class="divider po"></div>

            <div className="colsd">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <div class="section">
                    <h5 className=" g o">Employer Details </h5>
                    {/* <label for="uname" ><b>Full Name</b></label> */}
                    <input
                      id="a"
                      className="g"
                      type="text"
                      placeholder="Employer Firstname *"
                      required
                      onChange={(e) => setEfirstname(e.target.value)}
                    />

                    {/* <label for="uname"><b>UserEmail</b></label> */}
                    <input
                      id="b"
                      className="g"
                      type="text"
                      placeholder="Employer Secondname"
                      onChange={(e) => setEsecondname(e.target.value)}
                    />

                    <input
                      id="c"
                      className="g"
                      type="text"
                      placeholder="Employer Lastname *"
                      required
                      onChange={(e) => setElastName(e.target.value)}
                    />

                    <input
                      id="d"
                      className="g"
                      type="number"
                      placeholder="Employer Phone number *"
                      min="10"
                      max="10"
                      required
                      onChange={(e) => setEphone(e.target.value)}
                    />

                    <input
                      id="e"
                      className="g"
                      type="email"
                      placeholder="Employer email "
                      required
                      onChange={(e) => setEemail(e.target.value)}
                    />

                    <input
                      id="f"
                      className="g"
                      type="text"
                      placeholder="Employer Location *"
                      required
                      onChange={(e) => setElocation(e.target.value)}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div class="section">
                    <h5 className=" g o">Domestic Manager Details</h5>
                    {/* <label for="uname" ><b>Full Name</b></label> */}
                    <input
                      id="g"
                      className="g"
                      type="text"
                      placeholder=" Firstname *"
                      required
                      onChange={(e) => setMfirstname(e.target.value)}
                    />

                    {/* <label for="uname"><b>UserEmail</b></label> */}
                    <input
                      id="h"
                      className="g"
                      type="text"
                      placeholder=" Secondname"
                      onChange={(e) => setMsecondname(e.target.value)}
                    />
                    <input
                      id="i"
                      className="g"
                      type="text"
                      placeholder=" Lastname *"
                      required
                      onChange={(e) => setMlastName(e.target.value)}
                    />

                    <input
                      id="j"
                      className="g"
                      type="number"
                      placeholder=" Phone number *"
                      required
                      onChange={(e) => setMphone(e.target.value)}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>

            <h6 className="lb">
              <b>Please pay </b>{" "}
              <b>
                <font color="red">ksh 2500</font>
              </b>{" "}
              <b>to paybill: </b>{" "}
              <b>
                {" "}
                <font color="red">522533</font>
              </b>{" "}
              <b> Account no</b>{" "}
              <b>
                <font color="red">6347063</font>
              </b>
            </h6>

            {/* <Header>kljkljkljuiljkljkl</Header> */}
            <label for="uname">
              <b>
                {" "}
                <font color="black">MPESA CODE</font>
              </b>
            </label>
            <input
              id="k"
              type="text"
              placeholder=" Enter the Mpesa Code *"
              required
              onChange={(e) => setMpesa(e.target.value)}
            />

            {/* <h6 id="eroe" className="lb"> <b><font color='red'>enter all the field filled with a star <sup>*</sup></font></b></h6> */}

            <div id="div1" className="lb f"></div>

            <button id="subm" className="btt" onClick={(e) => submitt(e)}>
              Register
            </button>

            <Link to="/dashboard">
              <span className="dot"></span>
            </Link>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Registerpage;
