import React, { useEffect, useRef, useState } from "react";
import "./Login.css";
// import download from '../images/download.jpeg'
import { useStateValue } from "../redux/StateProvider";
import axios from "axios";
import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  Link,
  useHistory,
} from "react-router-dom";
import logo from "../logo/logo.png";
import { ToastContainer, toast } from "react-toastify";

function Login() {
  // const username = useRef()
  let history = useHistory();
  const [{ user }, dispatch] = useStateValue();
  const [email, setEmail] = useState();
  const [passwordd, setPasswordd] = useState();
  const [dis, setDis] = useState("none");

  const registerSuccess = () => toast.success("Loggedin successfully");
  const registererror = () => toast.error("An error occured");
  const registerAllField = () => toast.error("All fields are required");
  const registerWait = () => toast.success("Please wait as we log you in");

  useEffect(() => {}, [dis]);
  const mystyle = {
    color: "red",
    display: `${dis}`,
  };

  const Submitt = async (e) => {
    e.preventDefault();
    registerWait();
    await axios
      .post("https://sea-turtle-app-hk8mj.ondigitalocean.app/api/auth/login", {
        userEmail: email,
        password: passwordd,
      })
      .then((user) => {
        dispatch({
          type: "Log_In",
          name: user?.data?.userName,
        });

        dispatch({
          type: "Log_In_id",
          id: user?.data?._id,
        });
        registerSuccess();
      })
      .catch((error) => {
        registererror();
        console.log("error", error);
      });
  };

  const Submi = () => {
    dispatch({
      type: "Log_In",
      name: null,
    });
  };

  return (
    <>
      <div className="container  login-containert">
        <div className="card r">
          <div className="card-content">
            <img src={logo} alt="" className="logu" />

            <h5 className="lb">
              <b>First Aid Training For Domestic Managers</b>{" "}
            </h5>
            <h5 className="lb l">
              on 25<sup>th</sup> September 2021{" "}
            </h5>
            <div className="section"></div>
            <div className="divider po"></div>

            <div className="Login">
              <div className="login-containere">
                <h1 style={mystyle} className="i">
                  wrong email or password
                </h1>

                <form className="modal-content animate form">
                  <div className="container">
                    <label htmlFor="uname">
                      <b>Email</b>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Email"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />

                    <label htmlFor="psw">
                      <b>Password</b>
                    </label>
                    <input
                      type="password"
                      placeholder="Enter Password"
                      required
                      onChange={(e) => setPasswordd(e.target.value)}
                    />

                    <button className="button8" onClick={(e) => Submitt(e)}>
                      Login
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Login;
